import React, { Component, SyntheticEvent, useRef, useState, useEffect, useCallback, useReducer, MouseEvent, Dispatch} from 'react'

import { useStaticQuery, PageProps, Link, graphql} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Axios from "axios"
import * as yup from 'yup';

import Layout from "../components/layout"
import Seo from "../components/seo"
import SimpleModal from "../components/simple-modal"

type DataProps = {
  site: {
    buildTime: string
    siteMetadata: {
      title: string;
    }
  }
}

const schema = yup.object().shape({
  name: yup.string().required().max(60),
  email: yup.string().required().email().max(60),
  message: yup.string().max(500)
});

export default function ContactPage (props: PageProps<DataProps>) {

  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [message, setMessage] = useState('')

  const [disable, setDisable] = useState(false)

  const [modal, setModal] = useState<null | React.ReactElement>(null)

  const onSend = async () => {

    if (disable) return;

    setDisable(true)

    schema
      .validate({
        name: name,
        email: email,
        message: message
      }, {abortEarly: false})
      .then(async (value) => {

        try {

          const result: {message?: string, error?: string} = await Axios.post(
            "https://crssfeop60.execute-api.eu-west-1.amazonaws.com/v1/web/notifications?event=mlc_contact_form",
            {
                value1: name,
                value2: email,
                value3: message,
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );

          // TODO:
          // - ignore response and query for WO Completion record

          // let error = result?.error,
          //     response = result?.message;

          setName('')
          setEmail('')
          setMessage('')

          setModal(
            <SimpleModal
              onAction={() => {setModal(null)}}
              title="Thank You!"
              messages={["We aim to respond to all enquiries within 24 hours"]} />
          )

        } catch (err) {
          console.log(err);
        }

        setDisable(false)

        return true;
      })
      .catch((err) => {

        setDisable(false)

        setModal(
          <SimpleModal
            onAction={() => {setModal(null)}}
            title="Form validation failed"
            messages={err.errors} />
        )
      });
  }

  return (
    <Layout {...props}>
      <Seo title="Contact" />
      <section className="text-gray-600 body-font relative">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-12">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Contact Us</h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Aim to respond to all enquiries within 24 hours.</p>
          </div>
          <div className="lg:w-1/2 md:w-2/3 mx-auto">
            <div className="flex flex-wrap -m-2">
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label htmlFor="name" className="leading-7 text-sm text-gray-600">Name</label>
                  <input type="text" id="name" name="name" value={name} className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    onChange={e => setName(e?.target?.value)}
                    />
                </div>
              </div>
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                  <input type="email" id="email" name="email" value={email} className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    onChange={e => setEmail(e?.target?.value)}
                    />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label htmlFor="message" className="leading-7 text-sm text-gray-600">Message</label>
                  <textarea id="message" name="message" value={message} className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    onChange={e => setMessage(e?.target?.value)}
                    ></textarea>
                </div>
              </div>
              <div className="p-2 w-full">
                <button
                  className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                  onClick={() => {
                    onSend()
                  }}
                  >Send</button>
              </div>
              <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                {/*<span className="inline-flex">
                  <a className="text-gray-500">
                    <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                      <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                    </svg>
                  </a>
                  <a className="ml-4 text-gray-500">
                    <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                      <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                    </svg>
                  </a>
                  <a className="ml-4 text-gray-500">
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                      <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                      <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                    </svg>
                  </a>
                  <a className="ml-4 text-gray-500">
                    <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                      <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                    </svg>
                  </a>
                </span>*/}
              </div>
            </div>
          </div>
        </div>
      </section>

      {null !== modal ? modal : ''}

    </Layout>
  )
}

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
      siteMetadata {
        title
      }
    }
  }
`
